<template>
  <!-- 智慧园区 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧供水</div>
      <div class="title-p">
        生存与生活之间<br />
        也许只差一个媒介<br />
        农村 ( 城乡 ) 智慧供水系统解决方案<br />
        为百姓供好水
      </div>
    </div>
    <div class="function">
      <div class="scenes-box">
        <h3>方案优势</h3>
        <div class="scenes-bg">
          <div>
            <div class="leftTop">
              <img
                class="iconss"
                src="../../assets/image/wisdomWater/mzhineng.png"
                alt=""
              />
              智能
            </div>
            <div class="targetContent">
              智能远程管控、全自动运行减少运维、可视化管理
            </div>
          </div>

          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/image/wisdomWater/gaoxiao.png"
                alt=""
              />
              高效
            </div>
            <div class="targetContent">
              实时监测、在线预警、可关联水源地水质监测数据，匹配智能加药模型，优化药耗，产水安全可靠
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img
                class="iconss"
                src="../../assets/image/wisdomWater/fw.png"
                alt=""
              />
              服务
            </div>
            <div class="targetContent">
              配备设备管理、能耗成本管理、报警管理、报表管理、移动 app
              等，使业主从更加精细和动态的方式管理水厂的生产运行
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <h3>平台架构</h3>
      <div class="services">
        <div class="left">
          <img
            style="width: 100%; height: 100%"
            @click="onYuLan(img)"
            :src="img"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="function">
      <h3>功能场景</h3>
    </div>
    <div class="scene">
      <div class="img1">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">水质安全</div>
          <div class="sceneContents">
            实时在线监测水质余氮、PH值、浊度等水质数据,确保供水质量。
          </div>
        </div>
      </div>

      <div class="img2">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">工况管理</div>
          <div class="sceneContents">
            监测设备运营,远程手动或逻辑控制水泵的启、停、阀门的开度等。
          </div>
        </div>
      </div>
      <div class="img3">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">园区安防</div>
          <div class="sceneContents">
            监测现场安防、设备巡检、现场环境等情况。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return { img: require("../../assets/image/wisdomWater/wsjg.png") };
  },
  computed: {},
  watch: {},
  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="scss">
h3 {
  text-align: center;
  margin: 20px 0;
  letter-spacing: 5px;
}

.content-box {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  .title-box {
    padding-top: 40px;
    width: 100%;
    color: #fff;
    height: 281px;

    background: url("../../assets/image/wisdomWater/mwater.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      transform: translateY(30px);
      text-align: center;
      letter-spacing: 2px;
    }
    .title-p {
      font-size: 16px;

      transform: translateY(45px);
      color: #ffffff;
      text-align: center;
      letter-spacing: 2px;
      line-height: 28px;
    }
  }

  .function {
    background-color: #fff;

    .scenes-box {
      background-color: #fff;

      .scenes-bg {
        padding: 0 10px;

        // height: 170px;

        > div {
          width: 100%;
          //   height: 100%;
          height: 170px;
          background: url("../../assets/image/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          margin: 10px 0;
          .leftTop {
            width: 38%;
            height: 40px;
            line-height: 40px;
            text-align: left;
            color: #fff;
            border-bottom-right-radius: 25px;

            background-color: #c7b090;
            padding: 5px 0;
            font-weight: bold;
          }
          .targetContent {
            // margin: 10px;
            color: #6b6b6b;
            line-height: 30px;
            font-size: 15px;
            padding: 15px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-left: 20px;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
          margin-left: 20px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      padding: 0 10px;

      background-color: #fff;
      .left {
        width: 100%;
        height: 350px;
        margin-top: 4%;
        background: url("../../assets/image/wisdomWater/wsjg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
}

.scene {
  padding: 0 10px;
  margin-bottom: 20px;
  //   overflow: hidden;
  .sceneBox {
    position: relative;
    margin: 5px 0;

    .sceneContents {
      padding: 10px 30px;
      color: white;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
    }

    .sceneIcon {
      width: 44px;
      height: 44px;
    }
    .sceneTitle {
      position: relative;
      padding-top: 80px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;

      box-sizing: border-box;
      padding-bottom: 10px;
      text-align: center;
      //   border-bottom: 2px solid #fff;
      &::before {
        position: absolute;
        content: "";
        width: 120px;
        height: 1px;
        background-color: #fff;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    .sceneContent {
      color: #fff;
      margin: 10px 40px;
      z-index: 99;
      display: none;
    }
  }
}
.img1 {
  height: 220px;
  background: url("../../assets/image/wisdomWater/szafbg.png") no-repeat;
  background-size: 100% 100%;
  // margin: 0 5%;
}
.img2 {
  height: 220px;
  background: url("../../assets/image/wisdomWater/gkglbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}
.img3 {
  height: 220px;
  background: url("../../assets/image/wisdomWater/yqafbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}

.icon1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);

  background: url("../../assets/image/wisdomWater/szaf.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/image/wisdomWater/gkgl.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);

  background: url("../../assets/image/wisdomWater/yqaf.png") no-repeat;
  background-size: 100% 100%;
}
</style>
